import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { graphql } from "gatsby";
import { SpinnersBasic } from "../../examples/components/Spinners";
export const query = graphql`
  query Spinner {
    metadata: componentMetadata(displayName: { eq: "Spinner" }) {
      displayName
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Overview = makeShortcode("Overview");
const CodeBlock = makeShortcode("CodeBlock");
const LinkedHeading = makeShortcode("LinkedHeading");
const ComponentApi = makeShortcode("ComponentApi");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Overview mdxType="Overview">
  <p>
    Our spinners are built entirely using HTML and CSS. You can toggle their
    visibility using JavaScript in you project. Their appearance, alignment, and
    sizing can be easily customized with our utility classes.
  </p>
  <p>
    For accessibility purposes, each loader here includes{" "}
    <code>role="status"</code> and a nested{" "}
    <code>&lt;span className="sr-only"&gt;Loading...&lt;/span&gt;</code>.
  </p>
    </Overview>
    <p>Expected ways to use spinners:</p>
    <ul>
  <li>Use the border spinners for a lightweight loading indicator.</li>
  <li>
    Spinners should be centered within its container unless inside of another
    element like a button and use flexbox utilities, or text alignment utilities
    for placement.
  </li>
    </ul>
    <CodeBlock title="Example" code={SpinnersBasic} mdxType="CodeBlock"></CodeBlock>
    <LinkedHeading h="2" className="h1" id="spinners-api" mdxType="LinkedHeading">
  API
    </LinkedHeading>
    <ComponentApi metadata={props.data.metadata} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      